import React from "react"
import { getImage } from "gatsby-plugin-image"

import {
  HeroContent,
  HeroSubtitle,
  HeroTitle,
  HeroImageContainer,
} from "./HeroSection.styles"

const HeroSection = ({
  title,
  subTitle,
  backgroundImage: { alt, pluginImage },
}) => {
  //ANIMATION
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  const image = getImage(pluginImage)

  return (
    <HeroImageContainer image={image} alt={alt} id="home">
      <HeroContent>
        <HeroSubtitle
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 2 }}
        >
          {subTitle}
        </HeroSubtitle>
        <HeroTitle
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 1 }}
        >
          {title}
        </HeroTitle>
      </HeroContent>
    </HeroImageContainer>
  )
}

export default HeroSection

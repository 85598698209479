import styled from "styled-components"
import { BgImage } from "gbimage-bridge"
import { motion } from "framer-motion"

export const HeroImageContainer = styled(BgImage)`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;

  @media screen and (min-width: 769px) {
    height: 100vh;
  }
`

export const HeroContent = styled.div`
  /* Mobile First */
  padding: absolute;
  padding: 10em 24px;

  /* RESPONSIVE */
  @media screen and (min-width: 376px) {
    padding: 20em 24px;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
  }
`

export const HeroTitle = styled(motion.h1)`
  /* Mobile First */
  color: #000;
  margin-bottom: 8px;
  font-weight: 800;
  text-transform: capitalize;

  /* Responsive */
  @media screen and (min-width: 769px) {
    padding: 0 24px;
  }
`

export const HeroSubtitle = styled(motion.p)`
  /* Mobile First */
  font-size: 1rem;
  font-weight: 800;
  color: #333;

  text-transform: uppercase;
  text-align: right;
  word-spacing: 0.2rem;
  letter-spacing: 0.05rem;

  /* Responsive */
  @media screen and (min-width: 769px) {
    padding-right: 0.8em;
    font-size: 1.2rem;
  }
`

export const Button = styled.button`
  padding: 0 16px;
  height: 32px;
  width: 80px;
  border-radius: 4px;
  border: 0;
  background: #ed5f92;

  color: #fafafa;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  transition: background-color 0.2s;
`
